import { getCurrentInstance } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import Util from '@/common/utils/Util'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const { getters, dispatch } = useStore()

  const init = async () => {
    const startIndex = route.fullPath.indexOf('?')
    const jsonStr = route.fullPath.substr(startIndex + 1)
    const params = JSON.parse(decodeURIComponent(jsonStr))
    const detailForm = Object.assign(getters['user/getDetailForm'])
    detailForm.name = params.name
    detailForm.phone = params.phone
    detailForm.birth = params.birth
    detailForm.isPhoneAuth = proxy.$ConstCode.BOOLEAN_VALUE.TRUE
    detailForm.niceCnt++
    await dispatch('user/updateDetailForm', { detailForm })
    window.opener.parent.postMessage(
      {
        source: 'niceAuthPopSuccess',
        params: params
      },
      '*'
    )
    handlerClose()
  }

  const handlerClose = () => {
    window.addEventListener('message', function({ data }) {
      if (!Util.isEmpty(data.source) && data.source === 'niceAuthPopClose') {
        // 본인인증 콜백 message 처리
        window.self.close()
      }
    })
  }

  init()

  return { init, handlerClose }
}
